import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useAtomValue } from 'jotai/index';
import { useCallback } from 'react';

import { PartnerImage, PARTNERS_URI } from 'api/partnersApi';
import {
  getPartnerImagesQueryConfig,
  partnersQueryKeys,
  usePartnerImages,
} from 'api/partnersApi.hooks';
import { BLOB_URL_REGEXP, textsHaveBlobUrls } from 'components/RichTextEditor/utils';
import { LANGS } from 'constants/enums';
import { logError } from 'modules/Analytics/utils';

import { useArticlesApi } from './articlesApi';
import { articlesQueryKeys } from './queryKeys';
import { partnersAtoms } from '../../../state/partners';
import { getPartnerId } from '../../../utils/tokenUtils';
import { ARTICLE_THUMBNAIL_FILE_PREFIX } from '../constants';
import { Article, ArticleDraft } from '../types/article';

export const useFullArticle = ({
  partnerId,
  articleId,
}: {
  partnerId?: string | null;
  articleId: string;
}) => {
  const articlesApi = useArticlesApi();

  return useQuery({
    queryKey: articlesQueryKeys.getFullArticle({
      partnerId,
      id: articleId,
      type: 'selfcare',
    }),
    queryFn: async () => {
      if (!articlesApi) {
        throw new Error('articlesApi is not initialized');
      }
      if (!partnerId) {
        throw new Error('Missing partnerId');
      }

      return articlesApi
        .get('/articles/v1/:type/:articleId', {
          params: {
            type: 'selfcare',
            articleId,
          },
        })
        .then(response => ({ ...response, id: articleId }));
    },
    enabled: !!partnerId && !!articlesApi,
    refetchOnMount: false,
  });
};

// This is not good, and should ideally be done by the BE.
const generateNewImageName = ({
  imageName,
  partnerImageNames,
}: {
  imageName: string;
  partnerImageNames: string[];
}) => {
  const partnerImageNamesSet = new Set(partnerImageNames);
  let newImageName = imageName;

  let i = 1;
  while (partnerImageNamesSet.has(newImageName)) {
    newImageName = `${imageName}${i}`;
    i++;
  }

  return newImageName;
};

const getImageMetadata = (imageName: string, availableLanguages: LANGS[]) => {
  const titlesMap = {};
  const descriptionMap = {};

  availableLanguages.forEach(lang => {
    titlesMap[lang] = imageName;
    descriptionMap[lang] = '';
  });

  return {
    title: titlesMap,
    description: descriptionMap,
  };
};

const uploadMedicalImage = async ({
  partnerId,
  imageBlobPath,
  imageName,
  availableLanguages,
}: {
  partnerId: string;
  imageBlobPath: string;
  imageName: string;
  availableLanguages: LANGS[];
}) => {
  const imageBlob = await fetch(imageBlobPath).then(r => r.blob());
  const imageExtension = imageBlob.type.split('/')[1];
  const imageNameSanitized = imageName.replace(/[^a-zA-Z0-9_-]/g, '_');
  const imageFileName = `${imageNameSanitized}.${imageExtension}`;

  const imageFile = new File([imageBlob], imageFileName, {
    type: imageBlob.type,
  });
  const metadata = getImageMetadata(imageName, availableLanguages);

  const formData = new FormData();
  formData.append('image', imageFile, imageFileName);
  formData.append('metadata', JSON.stringify(metadata));

  await axios.post(`${PARTNERS_URI}/${partnerId}/articles/images/v1`, formData);

  return {
    name: imageNameSanitized,
    fileName: imageFileName,
    blob: imageBlob,
    metadata: metadata,
  };
};

const useUploadMedicalImage = () => {
  const queryClient = useQueryClient();
  const availableLanguages = useAtomValue(partnersAtoms.availableLanguages);

  return useMutation({
    mutationFn: async ({
      partnerId,
      partnerImageNames,
      imageName,
      blobPath,
    }: {
      partnerId: string;
      partnerImageNames: string[];
      imageName: string;
      blobPath: string;
    }) => {
      // This is completely suboptimal, and should be removed once the backend handles
      // this name duplication checking
      const generatedImageName = generateNewImageName({
        imageName,
        partnerImageNames,
      });

      return await uploadMedicalImage({
        partnerId,
        imageBlobPath: blobPath,
        imageName: generatedImageName,
        availableLanguages: availableLanguages,
      });
    },
    onSuccess: (image, { partnerId }) => {
      queryClient.setQueryData<Blob>(
        partnersQueryKeys.imageData(partnerId, image.fileName),
        image.blob
      );

      queryClient.setQueryData<PartnerImage[]>(partnersQueryKeys.images(partnerId), images => [
        ...(images || []),
        {
          fileName: image.fileName,
          source: partnerId,
          metadata: image.metadata,
        },
      ]);
    },
  });
};

export const useUpdateFullArticle = (partnerId: string) => {
  const articlesApi = useArticlesApi();
  const queryClient = useQueryClient();
  const uploadImage = useUploadMedicalImage();
  const uploadNewContentImages = useUploadNewContentImages();

  // Just preemptively triggering the fetching of partner images
  // this will be used in the mutation to check if the new thumbnail name is unique
  usePartnerImages(partnerId);

  return useMutation({
    mutationFn: async ({
      draft,
      uploadedImagesNamesMap,
    }: {
      draft: ArticleDraft;
      uploadedImagesNamesMap?: Map<string, string>;
    }) => {
      if (!articlesApi) {
        throw new Error('articlesApi is not initialized');
      }

      const { id, metadata: draftMetadataWithThumbnailBlob, texts } = draft;
      const { thumbnailBlobPath, thumbnailBlobName, ...updatedMetadata } =
        draftMetadataWithThumbnailBlob;

      // If the thumbnailBlobPath is present, but the image id (thumbnail) is not,
      // it means that the user has uploaded a new image in the browser, but it
      // has not been uploaded to the backend yet.
      const thumbnailImageNeedsToBeUploaded = thumbnailBlobPath && !updatedMetadata.thumbnail;

      let partnerImageNames: string[] = [];
      if (thumbnailImageNeedsToBeUploaded) {
        partnerImageNames = await queryClient
          .ensureQueryData(getPartnerImagesQueryConfig(partnerId))
          .then(images => images.map(image => image.fileName.split('.')[0]));
      }

      if (thumbnailImageNeedsToBeUploaded) {
        if (!thumbnailBlobName) {
          const error = 'Missing thumbnail blob name';
          logError(error, { type: 'invalid-state' });
          throw new Error(error);
        }

        const newThumbnail = await uploadImage.mutateAsync({
          partnerId,
          partnerImageNames,
          imageName: `${ARTICLE_THUMBNAIL_FILE_PREFIX}${thumbnailBlobName}`,
          blobPath: thumbnailBlobPath,
        });

        updatedMetadata.thumbnail = newThumbnail.name;
      }

      let updatedTexts = texts;
      if (textsHaveBlobUrls(texts)) {
        updatedTexts = await uploadNewContentImages(texts, uploadedImagesNamesMap);
      }

      await articlesApi.put(
        '/articles/v1/:type/:articleId',
        { metadata: updatedMetadata, texts: updatedTexts },
        {
          params: {
            type: 'selfcare',
            articleId: id,
          },
        }
      );

      return {
        updatedArticle: {
          ...draft,
          metadata: updatedMetadata,
        } as Article,
      };
    },
    onSuccess: ({ updatedArticle }) => {
      queryClient.setQueryData<Article>(
        articlesQueryKeys.getFullArticle({
          partnerId,
          id: updatedArticle.id,
          type: 'selfcare',
        }),
        updatedArticle
      );
    },
    retry: 2,
  });
};

export const useDeleteArticle = () => {
  const articlesApi = useArticlesApi();

  return useMutation({
    mutationFn: async (articleId: string) => {
      if (!articlesApi) {
        throw new Error('articlesApi is not initialized');
      }

      return articlesApi.delete('/articles/v1/:type/:articleId', undefined, {
        params: {
          type: 'selfcare',
          articleId,
        },
      });
    },
  });
};

/**
 * Uploads new content images to the backend.
 * The callback returns new texts object with the blob URLs replaced with the new image names.
 */
export const useUploadNewContentImages = () => {
  const partnerId = getPartnerId()!;
  const queryClient = useQueryClient();
  const uploadImage = useUploadMedicalImage();

  return useCallback(
    async (
      texts: { [key: LANGS[number]]: string },
      uploadedImagesNamesMap?: Map<string, string>
    ) => {
      const partnerImageNames = await queryClient
        .ensureQueryData(getPartnerImagesQueryConfig(partnerId))
        .then(images => images.map(image => image.fileName.split('.')[0]));

      if (!uploadedImagesNamesMap) {
        const message = 'Missing uploadedImagesNamesMap when content images need to be uploaded';
        logError(message, {
          type: 'invalid-state',
        });
        throw new Error(message);
      }

      const promises: (() => Promise<void>)[] = [];
      const result = {};

      Object.entries(texts).forEach(([lang, textContent]) => {
        if (!BLOB_URL_REGEXP.test(textContent)) {
          result[lang] = textContent;
          return;
        }

        const matches = Array.from(textContent.matchAll(new RegExp(BLOB_URL_REGEXP, 'gm')));
        for (const match of matches) {
          const [, blobObjectUrl] = match;
          const imageName = uploadedImagesNamesMap.get(blobObjectUrl);
          if (!imageName) {
            const error = 'Missing image name for blob object URL';
            logError(error, { type: 'invalid-state' });
            throw new Error(error);
          }

          promises.push(async () => {
            const newImage = await uploadImage.mutateAsync({
              partnerId,
              partnerImageNames,
              imageName,
              blobPath: blobObjectUrl,
            });

            result[lang] = textContent.replace(blobObjectUrl, newImage.name);
          });
        }
      });

      for (const promise of promises) {
        await promise();
      }

      return result;
    },
    [partnerId, queryClient, uploadImage]
  );
};
