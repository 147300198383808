import { notification } from 'antd';
import { action, computed, IObservableArray, observable, runInAction } from 'mobx';
import { IntlShape } from 'react-intl';
import { v4 as uuid } from 'uuid';

import {
  AccessScopeRole,
  fetchUserScopedRoles,
  saveUserScopedRoles,
  Scope,
  ScopedRoles,
} from 'api/permissionsApi';
import { ACCESS_SCOPE_TYPES } from 'constants/roles';
import RootStore from 'stores/RootStore';

import { getIds } from './storeUtils';
import { createCareUnitRoles, createOriginRoles, deleteManageRoles } from '../api/rolesApi';

export interface ManageViewScope {
  uuid: string;
  partnerId: string;
  scopeType: ACCESS_SCOPE_TYPES;
  scopeValue: string;
  scopeName?: string;
  roles?: AccessScopeRole[];
  children: ManageViewScope[];
  careProviderId?: string;
  careUnitId?: string;
  originId?: string;
  [key: string]: any;
}

export interface ManageRoleScope {
  uuid: string;
  partnerId: string;
  scopeType: ACCESS_SCOPE_TYPES;
  scopeValue: string;
  role?: string;
  children: ManageRoleScope[];
  [key: string]: any;
}

export default class ManageRolesStore {
  @observable
  private personalManageRolesOriginalData: IObservableArray<ScopedRoles> = observable.array([]);
  @observable activeScopedRole?: ManageViewScope;
  @observable newRole?: ManageRoleScope;
  @observable
  isLoading = false;

  constructor(
    private rootStore: RootStore,
    private practitionerId: string,
    private intl: IntlShape
  ) {}

  @computed
  get personalManageRoles(): ManageViewScope[] {
    const data = this.personalManageRolesOriginalData.map((elem: ScopedRoles) => {
      const partnerId = elem.partnerScope.scopeValue;

      const careProviders = elem.scopes.careProviders
        ? elem.scopes.careProviders.map(careProvider => {
            const careUnitChildren = careProvider?.careUnits?.map(careUnit =>
              this.mapToManageViewScope(careUnit, partnerId, [])
            );

            const careProviderViewScope = this.mapToManageViewScope(
              careProvider,
              partnerId,
              careUnitChildren
            );
            careProviderViewScope.uuid = `${partnerId}-${careProvider.scopeValue}`;
            careUnitChildren.forEach(careUnit => {
              careUnit.careUnitId = careUnit.scopeValue;
              careUnit.careProviderId = careProvider.scopeName;
            });
            careProviderViewScope.careProviderId = careProvider.scopeName;
            return careProviderViewScope;
          })
        : [];

      const origins = elem.scopes.origins
        ? elem.scopes.origins.map(origin => this.mapToManageViewScope(origin, partnerId, []))
        : [];

      return {
        uuid: elem.partnerScope.scopeValue,
        partnerId: partnerId,
        scopeValue: elem.partnerScope.scopeValue,
        'data-testid': elem.partnerScope.scopeValue,
        scopeType: ACCESS_SCOPE_TYPES.PARTNER,
        children: [
          ...(origins?.length > 0
            ? [
                {
                  uuid: `${partnerId}-${ACCESS_SCOPE_TYPES.ORIGIN}`,
                  'data-testid': `${partnerId}-${ACCESS_SCOPE_TYPES.ORIGIN}`,
                  scopeType: ACCESS_SCOPE_TYPES.ORIGIN,
                  scopeValue: ACCESS_SCOPE_TYPES.ORIGIN,
                  partnerId: elem.partnerScope.scopeValue,
                  children: origins,
                },
              ]
            : []),
          ...careProviders,
        ],
      };
    });

    return data;
  }

  @action
  fetchUserScopedRoles = async () => {
    try {
      this.isLoading = true;
      if (!this.practitionerId) {
        return;
      }
      const { data } = await fetchUserScopedRoles(this.practitionerId);
      runInAction(() => {
        this.personalManageRolesOriginalData.replace(data);
      });
      // This should fail silently
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  handleAddRole = () => {
    const { partnersStore } = this.rootStore;
    if (!partnersStore.currentPartner) {
      return;
    }

    this.newRole = {
      uuid: uuid(),
      scopeType: ACCESS_SCOPE_TYPES.CARE_UNIT,
      scopeValue: '',
      partnerId: partnersStore.currentPartner.id,
      children: [],
    };
  };

  @action
  handleEditScopedRoles = (data: ManageViewScope) => {
    this.activeScopedRole = data;
  };

  @action
  handleClearActiveRole = () => {
    this.activeScopedRole = undefined;
    this.newRole = undefined;
  };

  @action
  handleDeleteRole = async (data: ManageViewScope, practitionerId: string) => {
    const partner = this.personalManageRoles.find(elem => elem.partnerId === data.partnerId);

    if (!partner) return;

    const ids = getIds(data);

    try {
      const response = await deleteManageRoles(practitionerId, ids);
      if (
        Object.values(response?.data?.deletionStatuses || {}).includes(
          'FAILURE_DUE_TO_MISSING_PERMISSIONS'
        )
      ) {
        notification.error({
          message: this.intl.formatMessage({ id: 'delete.permission.error' }),
          duration: 0,
          placement: 'top',
        });
        return;
      }

      await this.fetchUserScopedRoles();

      notification.success({
        message: this.intl.formatMessage({ id: 'roles.notifications.role_removed' }),
        placement: 'top',
      });

      // This should fail silently
      // eslint-disable-next-line no-empty
    } catch {}
  };

  @action
  handleUpdateRole = (data: ManageViewScope) => {
    const partner = this.personalManageRoles.find(elem => elem.partnerId === data.partnerId);
    if (!partner) return;

    const updatedPartner = {
      ...partner,
      children: partner.children.map(section => ({
        ...section,
        children: section.children.map(scopedRole => {
          if (data.uuid === scopedRole.uuid) {
            return { ...data };
          }
          return scopedRole;
        }),
      })),
    };

    const updatedScopedRoles: ScopedRoles[] = this.personalManageRolesOriginalData.map(partner => {
      if (partner.partnerScope.scopeValue === updatedPartner.partnerId) {
        return this.mapToScopedRoles(updatedPartner);
      }
      return partner;
    });

    this.saveRoles(updatedScopedRoles);
  };

  handleSaveAddedRole = async (
    data: ManageRoleScope,
    practitionerId: string,
    careProviderId: string
  ) => {
    if (
      ![ACCESS_SCOPE_TYPES.ORIGIN, ACCESS_SCOPE_TYPES.CARE_UNIT].includes(data.scopeType) ||
      !data.role
    ) {
      return;
    }

    try {
      const { partnerId, role } = data;
      const payload = { role };
      if (data.scopeType === ACCESS_SCOPE_TYPES.ORIGIN) {
        await createOriginRoles({ partnerId, originId: data.scopeValue, practitionerId }, payload);
      } else {
        await createCareUnitRoles(
          { partnerId, careProviderId, practitionerId, careUnitId: data.scopeValue },
          payload
        );
      }
      this.fetchUserScopedRoles();
      this.handleClearActiveRole();
      // This should fail silently
      // eslint-disable-next-line no-empty
    } finally {
    }
  };

  createNewPartnerWithData = (data: ManageViewScope, careProviderId: string): ManageViewScope => {
    const partner: ManageViewScope = {
      uuid: uuid(),
      partnerId: data.partnerId,
      scopeValue: data.partnerId,
      scopeType: ACCESS_SCOPE_TYPES.PARTNER,
      children: [],
    };

    if (data.scopeType === ACCESS_SCOPE_TYPES.ORIGIN) {
      const origins: ManageViewScope = {
        uuid: uuid(),
        scopeType: ACCESS_SCOPE_TYPES.ORIGIN,
        scopeValue: ACCESS_SCOPE_TYPES.ORIGIN,
        partnerId: data.partnerId,
        children: [
          {
            ...data,
          },
        ],
      };
      partner.children.push(origins);
    } else {
      const careProvider: ManageViewScope = {
        uuid: uuid(),
        scopeType: ACCESS_SCOPE_TYPES.CARE_PROVIDER,
        scopeValue: careProviderId,
        partnerId: data.partnerId,
        children: [
          {
            ...data,
          },
        ],
      };
      partner.children.push(careProvider);
    }

    return partner;
  };

  addDataToExistingPartner = (
    partner: ManageViewScope,
    data: ManageViewScope,
    careProviderId: string
  ): ManageViewScope => {
    if (data.scopeType === ACCESS_SCOPE_TYPES.ORIGIN) {
      return {
        ...partner,
        children: partner.children.map(section => {
          if (section.scopeType === ACCESS_SCOPE_TYPES.ORIGIN) {
            return {
              ...section,
              children: [...section.children, data],
            };
          }
          return section;
        }),
      };
    }

    const careProviderExists = partner.children.some(
      section =>
        section.scopeType === ACCESS_SCOPE_TYPES.CARE_PROVIDER &&
        section.scopeValue === careProviderId
    );

    if (careProviderExists) {
      return {
        ...partner,
        children: partner.children.map(section => {
          if (
            section.scopeType === ACCESS_SCOPE_TYPES.CARE_PROVIDER &&
            section.scopeValue === careProviderId
          ) {
            return {
              ...section,
              children: [...section.children, data],
            };
          }
          return section;
        }),
      };
    }

    const newCareProvider: ManageViewScope = {
      uuid: uuid(),
      scopeType: ACCESS_SCOPE_TYPES.CARE_PROVIDER,
      scopeValue: careProviderId,
      partnerId: data.partnerId,
      children: [
        {
          ...data,
        },
      ],
    };
    return {
      ...partner,
      children: [...partner.children, newCareProvider],
    };
  };

  @action
  saveRoles = async (newRoles: ScopedRoles[]) => {
    try {
      this.isLoading = true;
      const { data } = await saveUserScopedRoles(this.practitionerId, newRoles);
      runInAction(() => {
        this.activeScopedRole = undefined;
        this.newRole = undefined;
        this.personalManageRolesOriginalData.replace(data);
      });
      notification.success({
        placement: 'top',
        message: this.intl.formatMessage({ id: 'roles.saved-message' }),
      });
    } catch (error: any) {
      if (error.request?.status === 400) {
        notification.error({
          placement: 'top',
          duration: 0,
          message: JSON.parse(error.request.response)?.message,
        });
      } else {
        notification.error({
          placement: 'top',
          duration: 0,
          message: this.intl.formatMessage({ id: 'general.error' }),
        });
      }
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  mapToScopedRoles = (partner: ManageViewScope): ScopedRoles => {
    const origins = partner.children.find(
      section => section.scopeType === ACCESS_SCOPE_TYPES.ORIGIN
    );
    const careProviders =
      partner.children.filter(section => section.scopeType === ACCESS_SCOPE_TYPES.CARE_PROVIDER) ||
      [];

    return {
      partnerScope: this.mapToScope(partner),
      scopes: {
        origins: origins ? origins.children.map(origin => this.mapToScope(origin)) : [],
        careProviders: careProviders.map(careProvider => ({
          scopeType: careProvider.scopeType,
          scopeValue: careProvider.scopeValue,
          scopeName: careProvider.scopeName || careProvider.scopeValue,
          careUnits: careProvider.children.map(careUnit =>
            this.mapToScope(careUnit, { careProvider: careProvider.scopeValue })
          ),
        })),
      },
    };
  };

  mapToScope = (data: ManageViewScope, additionalData: { [key: string]: any } = {}): Scope => ({
    scopeType: data.scopeType,
    scopeValue: data.scopeValue,
    scopeName: data.scopeName || data.scopeValue,
    'data-testid': data.scopeValue === '*' ? 'all' : data.scopeValue,
    roles: data.roles || undefined,
    ...additionalData,
  });

  mapToManageViewScope = (
    data: Scope,
    partnerId: string,
    children: ManageViewScope[]
  ): ManageViewScope => ({
    ...data,
    roles: data.roles || [],
    uuid: uuid(),
    'data-testid': data.scopeValue === '*' ? 'all' : data.scopeValue,
    partnerId,
    children,
  });
}
