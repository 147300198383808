import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Spin, Typography } from 'antd';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useBoolean } from 'hooks/useBoolean';

import { AnnouncementCard } from '../AnnouncementCard';
import { CreateAnnouncementModal } from '../createAnnouncementModal/CreateAnnouncementModal';
import { AnnouncementType } from '../createAnnouncementModal/types';
import { useGetAnnouncementsForCareProvider } from '../hooks';

export const CareUnitAnnouncement = () => {
  const { careProviderId, careUnitId } = useParams<{
    careProviderId?: string;
    careUnitId?: string;
  }>();
  const isCreateAnnouncementModalOpen = useBoolean(false);

  const { data: allAnnouncementsResult, isLoading } =
    useGetAnnouncementsForCareProvider(careProviderId);

  const announcementForThisCareUnit = useMemo(
    () =>
      allAnnouncementsResult?.data.careUnits.find(
        announcement => announcement.careUnitId === careUnitId
      ),
    [allAnnouncementsResult?.data.careUnits, careUnitId]
  );

  return (
    <>
      <Typography.Title level={2}>
        <FormattedMessage id="care-units.announcements" />
      </Typography.Title>

      <Typography.Paragraph>
        <FormattedMessage id="Notify healthcare personnel about critical information. Announcements are displayed at the top of the Clinic interface. There can be up to two active: one for the entire care provider and one for specific care units." />
      </Typography.Paragraph>

      <Typography.Title level={3}>
        <FormattedMessage id="Active announcements" />
      </Typography.Title>

      {isLoading ? (
        <Spin size="large" />
      ) : (
        <>
          {/* This care unit */}
          <Card
            style={{ maxWidth: 824 }}
            title={<FormattedMessage id="Visible to this care unit only" />}
          >
            {!announcementForThisCareUnit && (
              <Typography.Title level={4}>
                <FormattedMessage id="No active announcement" />
              </Typography.Title>
            )}

            <div>
              {announcementForThisCareUnit ? (
                <AnnouncementCard
                  key={announcementForThisCareUnit.id}
                  announcement={announcementForThisCareUnit}
                  type={AnnouncementType.CareUnit}
                />
              ) : (
                <Button onClick={isCreateAnnouncementModalOpen.setTrue} type="primary">
                  <PlusOutlined /> <FormattedMessage id="Create announcement" />
                </Button>
              )}
            </div>
          </Card>

          <br />

          {/* Care provider */}
          <Card
            style={{ maxWidth: 824 }}
            title={<FormattedMessage id="Visible to the entire care provider" />}
          >
            {allAnnouncementsResult?.data.careProvider ? (
              <AnnouncementCard
                announcement={allAnnouncementsResult.data.careProvider}
                linkToActionablePage={`/health-care/${careProviderId}/announcements`}
                type={AnnouncementType.CareProvider}
              />
            ) : (
              <>
                <Typography.Title level={4}>
                  <FormattedMessage id="No active announcement" />
                </Typography.Title>
                <Typography.Paragraph>
                  <FormattedMessage id="Create an announcement for the entire care provider by going to that care provider's settings and adding the announcement there." />
                </Typography.Paragraph>
              </>
            )}
          </Card>
        </>
      )}

      {!announcementForThisCareUnit && !!careProviderId && !!careUnitId && (
        <CreateAnnouncementModal
          careUnitId={careUnitId}
          careProviderId={careProviderId}
          handleCloseModal={isCreateAnnouncementModalOpen.setFalse}
          open={isCreateAnnouncementModalOpen.value}
          type={AnnouncementType.CareUnit}
        />
      )}
    </>
  );
};
