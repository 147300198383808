import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useState, useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import {
  getSelectedCareUnitForProvider,
  getSelectedProvidersAndCareUnits,
  getProvidersAndCareUnitsOptions,
} from './utils';
import { CareProviderResponse } from '../types';

interface InitialValues {
  careUnitIds: string[];
  careProviderIds: string[];
}

export const useCareUnitsAndCareProviders = (initialValues: InitialValues) => {
  const intl = useIntl();
  const [selectedCareUnits, setSelectedCareUnits] = useState<string[]>(
    initialValues.careUnitIds || []
  );
  const [selectedCareProviders, setSelectedCareProviders] = useState<string[]>(
    initialValues.careProviderIds || []
  );

  // Fetch data using react-query
  const { error, data, isLoading, isSuccess } = useQuery({
    queryKey: ['careProviderCareUnitFilter'],
    queryFn: async () => {
      const { data } = await axios.get<CareProviderResponse>('/rest/admin/v1/me/access');
      const careProviders = [
        {
          careUnits: [],
          id: 'ALL',
          name: intl.formatMessage({ id: 'All care providers' }),
          accessible: false,
        },
        ...(data?.careProviders || []),
      ];
      const { selectedCareProviders, selectedCareUnits } = getSelectedProvidersAndCareUnits(
        careProviders,
        initialValues.careProviderIds,
        initialValues.careUnitIds
      );
      setSelectedCareProviders(selectedCareProviders);
      setSelectedCareUnits(selectedCareUnits);
      return careProviders;
    },
  });

  // Memoize the options to avoid unnecessary recalculations on every render
  const { careUnitOptions, careProviderOptions } = useMemo(() => {
    const result = getProvidersAndCareUnitsOptions(data || [], selectedCareProviders);
    return result;
  }, [data, selectedCareProviders]);

  const onCareUnitChange = useCallback((value: string[]) => {
    setSelectedCareUnits(value);
  }, []);

  const onCareProviderChange = useCallback(
    (value: string[]) => {
      const hasAllCareProviderOptionSelected = value.includes('ALL');
      const isRemoval = value.length < selectedCareProviders.length;
      const isUnselectAll = !value.includes('ALL') && selectedCareProviders.includes('ALL');
      const isSelectAll = hasAllCareProviderOptionSelected && !isRemoval;
      setSelectedCareProviders(
        isUnselectAll
          ? []
          : isSelectAll
            ? careProviderOptions.map(cp => cp.value)
            : value.filter(cpId => cpId !== 'ALL') // value left after removal minus the ALL option so that All is also deselected
      );
      setSelectedCareUnits(getSelectedCareUnitForProvider(data ?? [], selectedCareUnits, value)); // allow old selection of care units if care unit is in the selected care provider else reset the care units selection
    },
    [data, selectedCareProviders, selectedCareUnits]
  );

  const clearCareProvidersAndCareUnitsSelection = useCallback(() => {
    setSelectedCareProviders([]);
    setSelectedCareUnits([]);
  }, []);

  // Return object
  return {
    error,
    selectedCareProviders,
    selectedCareUnits,
    careProviders: careProviderOptions || [],
    careUnits: careUnitOptions || [],
    isCareProviderLoading: isLoading,
    isCareProviderAndCareUnitSuccess: isSuccess,
    onCareUnitChange,
    onCareProviderChange,
    clearCareProvidersAndCareUnitsSelection,
  };
};
