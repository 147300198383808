import { MedicineBoxOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import React, { Component, Fragment, ContextType, ComponentType } from 'react';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { RouteComponentProps, Switch, Route } from 'react-router-dom';

import { AppBreadcrumbItem } from 'components/Breadcrumbs/Breadcrumbs';
import PageWithSectionsContainer from 'components/PageWithSectionsContainer';
import PageWithSectionsContent from 'components/PageWithSectionsContent';
import PageWithSectionsHeader from 'components/PageWithSectionsHeader';
import SideMenu from 'components/SideMenu';
import { DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT } from 'constants/general';
import RootStoreContext from 'context/RootStoreContext';
import { CareUnitAnnouncement } from 'modules/Announcements/careUnit/CareUnitAnnouncements';
import PartnerStatus from 'modules/PartnerStatus';
import { notification } from 'services/NotificationService';

import CareUnitBasicDetails from './CareUnitBasicDetails';
import Customizations from './Customizations';
import { OpeningHoursCareUnit } from './OpeningHours';
import Phrases from './Phrases';

interface Props
  extends WrappedComponentProps,
    RouteComponentProps<{ careProviderId: string; careUnitId: string }> {}

@observer
class CareUnits extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  basePath = '/health-care/:careProviderId/care-units/:careUnitId';

  get breadcrumbs(): AppBreadcrumbItem[] {
    const {
      match: {
        params: { careProviderId, careUnitId },
      },
      location,
    } = this.props;

    const { careUnitStore, careUnitsStore } = this.context;

    const breadcrumbs = [
      {
        icon: <MedicineBoxOutlined />,
        text: <FormattedMessage id="health-care" />,
        link: '/health-care',
      },
      {
        text: careUnitsStore.currentCareProvider?.name ?? careProviderId,
        link: `/health-care/${careProviderId}`,
      },
      {
        text: <FormattedMessage id="care-units.care-units" />,
        link: `/health-care/${careProviderId}/care-units`,
      },
      {
        text: careUnitStore.careUnit?.name ?? careUnitId,
        link: `/health-care/${careProviderId}/care-units/${careUnitId}/basic-details`,
      },
    ];

    const matchingSubRoute = this.routes.find(({ path }) => location.pathname.includes(path));
    if (matchingSubRoute) {
      breadcrumbs.push({
        text: this.props.intl.formatMessage({ id: `care-units.${matchingSubRoute.path}` }),
        link: `/health-care/${careProviderId}/care-units/${careUnitId}/${matchingSubRoute.path}`,
      });
    }

    return breadcrumbs;
  }

  async componentDidMount() {
    const {
      match: {
        params: { careProviderId, careUnitId },
      },
    } = this.props;
    const { careUnitStore, careUnitsStore } = this.context;

    try {
      await careUnitStore.fetchCareUnit(careProviderId, careUnitId);
      await careUnitsStore.fetchCareProvider(careProviderId);
    } catch {
      this.handleNotFound();
    }
  }

  async componentDidUpdate(prevProps: Props) {
    const {
      match: {
        params: { careProviderId, careUnitId },
      },
    } = this.props;
    const { careUnitStore, careUnitsStore } = this.context;

    if (prevProps.match.params.careUnitId !== careUnitId) {
      try {
        await careUnitStore.fetchCareUnit(careProviderId, careUnitId);
        await careUnitsStore.fetchCareProvider(careProviderId);
      } catch (e: unknown) {
        this.handleNotFound();
      }
    }
  }

  componentWillUnmount() {
    this.context.careUnitStore.dispose();
  }

  handleNotFound = () => {
    const { match, history } = this.props;
    notification.error({
      placement: 'top',
      duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
      message: this.props.intl.formatMessage(
        { id: 'care-units.errors.not-found' },
        { id: match.params.careUnitId }
      ),
    });

    history.push('/care-units');
  };

  get routes() {
    const {
      match: {
        params: { careUnitId },
      },
    } = this.props;
    const {
      userDataStore: { userCareUnits },
      careUnitStore,
      partnersStore,
      userPermissionsStore,
    } = this.context;
    const isUsersCareUnit = userCareUnits.some(({ id }) => careUnitId === id);
    const routes: { testid: string; path: string; component: ComponentType<any> }[] = [
      {
        path: 'basic-details',
        component: CareUnitBasicDetails,
        testid: 'careunit-basic-details',
      },
    ];

    if (userPermissionsStore.isSuperAdmin || userPermissionsStore.isNewRoleSuperAdmin) {
      routes.push({
        path: 'customizations',
        component: Customizations,
        testid: 'careunit-customizations',
      });
    }

    if (isUsersCareUnit || careUnitStore.canBeEditedByMe) {
      if (partnersStore.partnerCustomizations.get('ENABLE_OPENING_HOURS_IN_ADMIN')) {
        routes.push({
          path: 'opening-hours',
          component: OpeningHoursCareUnit,
          testid: 'careunit-opening-hours',
        });
      }
      routes.push(
        {
          path: 'announcements',
          component: CareUnitAnnouncement,
          testid: 'careunit-announcements',
        },
        {
          path: 'phrases',
          component: Phrases,
          testid: 'careunit-phrases',
        }
      );
    }

    return routes;
  }

  get sideMenuLinks() {
    const {
      match: {
        params: { careUnitId, careProviderId },
      },
    } = this.props;

    return this.routes.map(({ path, testid }) => {
      const fullPath = `/health-care/${careProviderId}/care-units/${careUnitId}/${path}`;

      return {
        url: fullPath,
        id: fullPath,
        testid,
        content: this.props.intl.formatMessage({ id: `care-units.${path}` }),
      };
    });
  }

  render() {
    const {
      careUnitStore: { careUnit },
    } = this.context;

    if (careUnit === undefined) {
      return null;
    }

    return (
      <Fragment>
        <PartnerStatus />
        <PageWithSectionsHeader
          title={
            <span>
              <FormattedMessage id="care-units.care-units" />: {careUnit.name}
            </span>
          }
          breadcrumbs={this.breadcrumbs}
        />
        <PageWithSectionsContainer>
          <SideMenu links={this.sideMenuLinks} />
          <PageWithSectionsContent>
            <Switch>
              {this.routes.map(({ path, component }) => (
                <Route key={path} path={`${this.basePath}/${path}`} component={component} />
              ))}
            </Switch>
          </PageWithSectionsContent>
        </PageWithSectionsContainer>
      </Fragment>
    );
  }
}

export default injectIntl(CareUnits);
